const hasLocalhost = (hostname: string) =>
  Boolean(
    hostname.match(/localhost/) ||
      hostname.match(/127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/)
  );
const isLocalhost = hasLocalhost(window.location.hostname);
export const environment = {
  stage: 'dev',
  userPoolId: 'eu-west-1_ZifETEFAt',
  region: 'eu-west-1',
  clientId: '77m510sv0ss7oug1tb9g5rhoks',
  cognitoDomainPrefix: 'cxp-admins-dev',
  cognitoRedirectUri: isLocalhost
    ? 'http://localhost:4200/secure'
    : 'https://client.catenax-dev.draexlmaier.solutions/secure',
  cognitoLogoutUri: isLocalhost
    ? 'http://localhost:4200/login'
    : 'https://client.catenax-dev.draexlmaier.solutions/login',
  assetManagementApiUrl: 'https://hruonk8ked.execute-api.eu-west-1.amazonaws.com/dev',
  assetTransferApiUrl: 'https://6a4memrpn5.execute-api.eu-west-1.amazonaws.com/dev',
};
